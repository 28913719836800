<template>
  <div class="AnnouncementList common-page">
    <PocCardTable>
      <template slot="headerTitle">
        <span class="font21 colorB">{{ $t('dc:用户组管理') }}</span>
        <span class="padRL6 font21">/</span>
        <span class="font21">{{ $t('dc:公告列表') }}</span>
      </template>
      <template slot="headerGroup">
        <el-select
          v-model="pageConfig.projectId"
          filterable
          :clearable="true"
          :disabled="routerQuery"
          :placeholder="$t('dc:请选择产品')"
          @change="queryApi"
          style="margin-right: 10px; width: 240px"
        >
          <el-option
            v-for="item in productList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-button class="btn-black" type="info" @click="onAdd">{{
          $t('dc:新增公告信息')
        }}</el-button>
        <!-- <el-button type="primary" icon="el-icon-refresh">{{$t('dc:刷新')}}</el-button> -->
      </template>
      <template slot="queryList"> </template>
      <PocTable
        v-loading="loading"
        :data="tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        :border="false"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
      >
        <el-table-column
          v-for="item in columnConfig"
          :key="item.key"
          :prop="item.chlidren ? null : item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sorter="item.sortable"
          :width="item.key === 'no' ? 60 : null"
          :min-width="item.width"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <!-- <span v-if="item.key === 'AnnouncementCode'">
              <span>{{ scope.row[item.key] | AnnouncementCodeListOne }}</span
              ><span style="font-weight: 700">{{
                scope.row[item.key] | AnnouncementCodeListTwo
              }}</span>
            </span> -->
            <span>{{ scope.row[item.key] || '-' }}</span>
          </template>
          <!-- <el-table-column
            v-for="chlid in item.chlidren"
            v-if="chlid.visible"
            :key="chlid.key"
            :prop="chlid.key"
            :label="chlid.name"
            :fixed="chlid.fixed"
            :sorter="chlid.sortable"
            :min-width="item.width"
            show-overflow-tooltip
          >
          </el-table-column> -->
        </el-table-column>

        <el-table-column :label="$t('dc:操作')" width="150" fixed="right" align="center">
          <template slot-scope="scope">
            <div class="action-btn-group">
              <!-- <el-tooltip effect="dark" :content="$t('dc:查看')" placement="top">
                <i
                  class="poc-icon-info poc-size12 iconfont icon-oneui-look padding12 nicon"
                  @click="showView(scope.row)"
                ></i>
              </el-tooltip> -->
              <el-tooltip
                effect="dark"
                :content="$t('dc:编辑')"
                placement="top"
                :style="{ cursor: !scope.row.canEdit ? 'not-allowed' : 'pointer' }"
              >
                <i
                  class="poc-icon-info poc-size12 iconfont icon-oneui-contract-edit padding12 nicon"
                  :class="{ disabled: !scope.row.canEdit }"
                  @click="showEdit(scope.row)"
                ></i>
              </el-tooltip>

              <el-tooltip effect="dark" :content="$t('dc:删除')" placement="top">
                <i
                  class="poc-icon-info poc-size12 iconfont icon-oneui-delete padding12 nicon"
                  @click="delectAnnouncement(scope.row)"
                ></i>
              </el-tooltip>
              <el-tooltip
                v-if="scope.row.status"
                effect="dark"
                :content="$t('dc:开')"
                placement="top"
              >
                <i
                  class="poc-icon-info poc-size12 iconfont icon-oneui-eyes-open padding12 nicon"
                  @click="switchAnnouncement(scope.row)"
                ></i>
              </el-tooltip>
              <el-tooltip v-else effect="dark" :content="$t('dc:关')" placement="top">
                <i
                  class="poc-icon-info poc-size12 iconfont icon-oneui-eyes-close padding12 nicon"
                  @click="switchAnnouncement(scope.row)"
                ></i>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </PocTable>
    </PocCardTable>
    <add2EditAnnouncementDialog
      v-if="showAnnouncementDialog"
      :visible.sync="showAnnouncementDialog"
      :title="title"
      :showStatus="showStatus"
      :rowData="rowData"
      @updDataList="updDataList"
    ></add2EditAnnouncementDialog>
    <Forbid4Delete
      v-if="showAnnouncementDelDialog"
      :visible.sync="showAnnouncementDelDialog"
      :title="delTitle"
      :content="delContent"
      @confrom="confrom"
    ></Forbid4Delete>
  </div>
</template>
<script>
import Forbid4Delete from '@/views/dialog/usuallyDialog'
import add2EditAnnouncementDialog from '@/views/dialog/allAnnouncementDialog'
export default {
  name: 'AnnouncementList',
  mixins: [$PCommon.TableMixin],
  components: {
    add2EditAnnouncementDialog,
    Forbid4Delete,
  },
  data() {
    return {
      AnnouncementName: '',
      title: this.$t('dc:新增公告信息'),
      delTitle: this.$t('dc:提醒'),
      delContent: this.$t('dc:确定删除?'),
      content: '',
      pageConfig: {
        projectId: this.$route.query.projectId,
      },
      productList: [],
      loading: false,
      showAnnouncementDialog: false,
      showAnnouncementDelDialog: false,
      showStatus: false,
      Announcementobj: {},
      tableData: [],
      rowData: {},
      routerQuery: false,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (to.query.projectId) this.routerQuery = true
        else this.routerQuery = false
        if (!this.routerQuery) {
          this.pageConfig.projectId = ''
          this.queryApi()
        }
      },
    },
  },
  computed: {
    columnConfig() {
      return [
        {
          key: 'announcementId',
          name: 'No.',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: 60, // 默认宽度，像素
        },
        {
          key: 'projectName',
          name: this.$t('dc:产品名称'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false,
          width: 80,
        },
        {
          key: 'title',
          name: this.$t('dc:名称'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 120,
        },
        {
          key: 'clientName',
          name: this.$t('dc:分配给客户'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 100,
        },
        {
          key: 'launchTime',
          name: this.$t('dc:发布时间'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 180,
        },
        {
          key: 'author',
          name: this.$t('dc:发布者'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 180,
        },
      ].filter((n) => n.visible)
    },
  },
  created() {},
  mounted() {
    this.initEvent()
  },
  methods: {
    initEvent() {
      this.getProductList()
      this.queryApi()
    },
    // 获取产品列表
    async getProductList() {
      let { status, data } = await $Api.project.optionList()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.productList = data
      }
    },
    async queryApi() {
      this.loading = true
      let { status, data } = await $Api.announcement.announcementList(this.pageConfig)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.tableData = data.list
        this.pageConfig.total = data.total
      }
      this.loading = false
    },
    updDataList() {
      this.queryApi()
    },
    onAdd() {
      this.rowData = {}
      this.showStatus = true
      this.title = this.$t('dc:新增公告信息')
      this.showAnnouncementDialog = true
    },
    showView(row) {
      this.showStatus = false
      this.rowData = row
      this.title = this.$t('dc:公告信息详情')
      this.showAnnouncementDialog = true
    },
    showEdit(row) {
      if (!row.canEdit) return
      this.showStatus = true
      this.rowData = row
      this.title = this.$t('dc:编辑公告信息')
      this.showAnnouncementDialog = true
    },
    delectAnnouncement(row) {
      this.rowData = row
      this.showAnnouncementDelDialog = true
    },
    async confrom() {
      let { status, data } = await $Api.announcement.delAnnouncement({
        announcementId: this.rowData.announcementId,
      })
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.showAnnouncementDelDialog = false
        this.queryApi()
      }
    },
    async switchAnnouncement(row) {
      let { status, data } = await $Api.announcement.updStatusAnnouncement({
        announcementId: row.announcementId,
        status: !row.status,
      })
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.$message({
          showClose: true,
          message: this.$t('dc:成功') || 'success',
          type: 'success',
        })
        this.queryApi()
      }
    },
  },
}
</script>
