<template>
  <div>
    <el-dialog
      :visible.sync="show"
      width="55%"
      top="2%"
      :close-on-click-modal="false"
      class="add2Eidt-Announcement-dialog portrait"
      :title="title"
      @close="closed"
    >
      <div :class="{ paddingB16: !showStatus }">
        <el-form
          :model="announcementDataForm"
          :rules="rulesList"
          ref="AnnouncementDataForm"
          class="demo-ruleForm"
        >
          <el-form-item prop="projectId">
            <template slot="label">
              <div>
                {{ $t('dc:产品名称') }}
                <em>*</em>
              </div>
            </template>
            <div>
              <el-select
                style="width: 100%"
                v-model="announcementDataForm.projectId"
                filterable
                :clearable="true"
                :disabled="routerQuery || !showStatus"
                :placeholder="$t('dc:请选择')"
              >
                <el-option
                  v-for="item in productList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item prop="launchTime">
            <template slot="label">
              <div>
                {{ $t('dc:发布时间') }}
                <em>*</em>
              </div>
            </template>
            <div>
              <el-date-picker
                style="width: 100%"
                v-model="announcementDataForm.launchTime"
                type="date"
                value-format="yyyy-MM-dd"
                :disabled="!showStatus"
                :picker-options="pickerOptions"
                :placeholder="$t('dc:选择日期')"
              >
              </el-date-picker>
            </div>
          </el-form-item>

          <el-form-item prop="title">
            <template slot="label">
              <div>
                {{ $t('dc:名称') }}
                <em>*</em>
              </div>
            </template>
            <div>
              <el-input
                v-model="announcementDataForm.title"
                :disabled="!showStatus"
                :placeholder="$t('dc:请输入')"
              ></el-input>
            </div>
          </el-form-item>

          <el-form-item :label="$t('dc:分配给客户')">
            <template slot="label">
              <div>
                {{ $t('dc:分配给客户') }}
              </div>
            </template>
            <div>
              <el-select
                style="width: 100%"
                v-model="announcementDataForm.clientId"
                filterable
                :clearable="true"
                :disabled="routerQuery || !showStatus"
                :placeholder="$t('dc:请选择')"
              >
                <el-option
                  v-for="item in clientOptionList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item prop="content">
            <template slot="label">
              <div>
                {{ $t('dc:发布内容') }}
                <em>*</em>
              </div>
            </template>
            <div class="categoryContent">
              <!-- <el-input
                  type="textarea"
                  :disabled="!showStatus"
                  :rows="5"
                  :placeholder="$t('dc:请输入')"
                  v-model="announcementDataForm.content"
                >
                </el-input> -->
              <SSOMarkDown
                :class="{ showMsg: !showStatus }"
                ref="markdownEditor"
                height="300px"
                width="100%"
                v-model="announcementDataForm.content"
              ></SSOMarkDown>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" v-if="showStatus">
        <el-button class="marginR20" type @click="show = false">{{ $t('dc:取消') }}</el-button>
        <el-button class="btn-black" type="primary" @click="submitForm('AnnouncementDataForm')">{{
          $t('dc:确认')
        }}</el-button>
      </div>
    </el-dialog>
    <UsuallyDialog
      v-if="show4Dialog"
      :visible.sync="show4Dialog"
      :title="dielogTitle"
      :content="content"
      @confrom="confrom"
    ></UsuallyDialog>
  </div>
</template>
<script>
import config from '@/api/config'
import { $http } from '../../api/http'
import UsuallyDialog from '@/views/dialog/usuallyDialog'
export default {
  name: 'Add2EditAnnouncementDialog',
  components: {
    UsuallyDialog,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    showStatus: {
      type: Boolean,
      default: false,
    },
    rowData: {},
  },
  data() {
    var titleRule = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
      } else {
        callback()
      }
    }
    var contentRule = (rule, value, callback) => {
      if (this.$refs.markdownEditor.getValue() === '\n') {
        callback(new Error(this.$t('dc:请按规则输入')))
      } else {
        callback()
      }
    }
    var launchTimeRule = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
      } else {
        callback()
      }
    }
    var projectIdRule = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('dc:请选择')))
      } else {
        callback()
      }
    }
    return {
      show: this.visible,
      otherClass: 'home',
      productList: [],
      clientOptionList: [],
      routerQuery: false,
      show4Dialog: false,
      announcementDataForm: {
        projectId: this.$route.query.projectId || '',
        announcementId: this.rowData.announcementId || '',
        clientId: this.rowData.clientId || '',
        launchTime: '',
        title: '',
        content: '',
      },

      textarea: '',
      DateValue: '',
      input: '',
      dielogTitle: this.$t('dc:提醒'),
      content: this.$t('dc:确认添加'),
      rulesList: {
        projectId: [{ validator: projectIdRule, trigger: 'blur' }],
        launchTime: [{ validator: launchTimeRule, trigger: ['blur', 'change'] }],
        title: [{ validator: titleRule, trigger: 'blur' }],
        clientId: [{ validator: projectIdRule, trigger: 'blur' }],
        content: [{ validator: contentRule, trigger: 'blur' }],
      },
    }
  },
  watch: {
    show(v) {
      this.$emit('update:visible', v)
    },
    visible(v) {
      this.show = v
    },
  },
  computed: {
    pickerOptions() {
      const _that = this
      return {
        firstDayOfWeek: 1,
        disabledDate(time) {
          return time.getTime() <= Date.now() - 86400000
        },
      }
    },
  },
  mounted() {
    this.initData()
    this.ininEvent()
  },

  methods: {
    initData() {
      if (this.$route.query.projectId !== undefined) this.routerQuery = true
      else this.routerQuery = false
      if (this.rowData.announcementId) {
        this.content = this.$t('dc:确认修改')
        this.routerQuery = true
      } else this.content = this.$t('dc:确认添加')
    },
    ininEvent() {
      this.getProductList()
      this.getClientOptionList()
      if (this.rowData.announcementId)
        this.getAnnouncementDetil({ announcementId: this.rowData.announcementId })
    },
    // 获取产品列表
    async getProductList() {
      let { status, data } = await $Api.project.optionList()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.productList = data
      }
    },
    // 获取客户列表
    async getClientOptionList() {
      let { status, data } = await $Api.common.getAllClientList()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.clientOptionList = data
      }
    },
    // 获取公告详情
    async getAnnouncementDetil(param) {
      let { status, data } = await $Api.announcement.detailsAnnouncement(param)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.announcementDataForm.projectId = data.projectId
        this.announcementDataForm.launchTime = data.launchTime
        this.announcementDataForm.title = data.title
        this.announcementDataForm.content = data.content
        this.$refs.markdownEditor.setValue(data.content)
      }
    },
    async addAnnouncement(param) {
      let { status } = await $Api.announcement.addAnnouncement(param)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.show = false
        this.$message({
          showClose: true,
          message: this.$t('dc:成功'),
          type: 'success',
        })
        this.$emit('updDataList')
      }
    },
    async editAnnouncement(param) {
      let { status } = await $Api.announcement.updAnnouncement(param)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.show = false
        this.$message({
          showClose: true,
          message: this.$t('dc:成功'),
          type: 'success',
        })
        this.$emit('updDataList')
      }
    },
    closed() {
      this.$emit('close')
      this.show = false
    },
    confrom() {
      this.show4Dialog = false
      if (this.rowData.announcementId) this.editAnnouncement(this.announcementDataForm)
      else this.addAnnouncement(this.announcementDataForm)
    },
    submitForm(formName) {
      this.announcementDataForm.content = this.$refs.markdownEditor.getValue()
      console.log(this.$refs.markdownEditor.getValue() == '\n')
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.show4Dialog = true
        } else {
          return false
        }
      })
    },
  },
}
</script>
<style lang="scss">
em {
  color: red;
}
.paddingB16 {
  padding-bottom: 2px;
}
.categoryContent .showMsg {
  cursor: not-allowed;
  pointer-events: none;
}
</style>
